import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const Loader = () => {
  return (
    <div className="absolute w-full h-full flex justify-center items-center bg-white bg-opacity-60 z-50">
      <Spin indicator={<LoadingOutlined style={{ fontSize: "4rem" }} spin />} />
    </div>
  );
};
