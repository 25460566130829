import React from "react";
import ReactDOM from "react-dom/client";
import { Routers } from "./routers";
import "./app/styles/index.less";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider prefixCls="bf">
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </ConfigProvider>
    {/* h */}
    <ToastContainer theme="colored" />
  </React.StrictMode>
);
