import type { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { ICategories, Query } from "@types";
import { toast } from "react-toastify";
import { categoriesApi } from "@api";

export const useCategories = () => {
  const [categories, setCategories] = useState<any>(undefined);

  const getCategories = async (query?: Query<ICategories>) => {
    const res = await categoriesApi.getMany({
      ...query,
      fields: ["category"],
    });

    if (res.ok) {
      let Rows: DefaultOptionType[] = [];

      res.data.map((category: ICategories) => {
        return Rows.push({
          label: category.category,
          value: category.id,
        });
      });

      setCategories(Rows);

      return;
    }

    if (res.error === "SERVER") {
      toast.error(res.message);

      return;
    }

    console.log(res.message);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return {
    categories,
  };
};
