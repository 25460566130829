import { useNavigate } from "react-router-dom";
import { useState } from "react";
import config from "@config";
import axios from "axios";

interface Login {
  email: string;
  password: string;
}

export const useLogin = () => {
  const [errors, setErrors] = useState<any>(undefined);
  const navigation = useNavigate();

  const onSubmit = async (login: Login) => {
    try {
      setErrors(undefined);

      const res = await axios.post(
        config.API_HOST + "/api/v1/auth/login",
        login
      );

      if (res.data.role !== "admin") {
        setErrors("Unauthorize User.");
        return;
      }

      localStorage.setItem("token", res.data.access_token);

      navigation("/");
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 401) {
          setErrors(error.response.data.message);
        }
      }
    }
  };

  return {
    onSubmit,
    errors,
  };
};
