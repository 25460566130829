import type { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ICity, Query } from "@types";
import { citiesDevApi } from "@api";

export const useCityDev = () => {
  const [citiesDev, setCituesDev] = useState<any>(undefined);

  const getCitiesDev = async (query?: Query<ICity>) => {
    const res = await citiesDevApi.getMany({
      ...query,
      fields: ["label"],
      limit: 100000000000,
    });

    if (res.ok) {
      let Rows: DefaultOptionType[] = [];

      res.data.data.map((cityDev: ICity) => {
        return Rows.push({
          label: cityDev.label,
          value: cityDev.id,
        });
      });

      setCituesDev(Rows);

      return;
    }

    if (res.error === "SERVER") {
      toast.error(res.message);

      return;
    }

    console.log(res.message);
  };

  useEffect(() => {
    getCitiesDev();
  }, []);

  return {
    citiesDev,
  };
};
