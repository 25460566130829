import { Dropdown, Menu, notification, Tooltip } from "antd";
import { FaTrash } from "react-icons/fa";
import { mediaApi } from "@api";
import { IMedia } from "@types";
import config from "@config";

interface CardProps {
  image: IMedia;
  onLoad?: () => void;
  onClick?: (image: IMedia) => void;
}

export const Card: React.FC<CardProps> = ({ image, onLoad, onClick }) => {
  const onDelete = async () => {
    const res = await mediaApi.deleteOne(image?.id || 0);

    if (res.ok) {
      onLoad?.();

      return;
    }

    if (res.error === "SERVER") {
      notification.error({
        message: res.message,
      });

      return;
    }

    console.log(res.message);
  };

  const menu = (
    <Menu
      items={[
        {
          label: "Delete",
          key: "Delete",
          icon: <FaTrash />,
          onClick: onDelete,
          danger: true,
        },
      ]}
    />
  );

  return (
    <Tooltip title={image?.name?.split("-")[1]}>
      <Dropdown overlay={menu} trigger={["contextMenu"]}>
        <div
          onClick={() => {
            onClick?.(image);
          }}
          className="w-48 h-24 overflow-hidden rounded cursor-pointer img-scale"
        >
          <img
            className="w-full object-cover"
            crossOrigin="anonymous"
            src={config.IMAGE_URL + image.images?.thumbnail?.url}
            alt={image.name}
          />
        </div>
      </Dropdown>
    </Tooltip>
  );
};
