import {
  FaBlog,
  FaCity,
  FaHome,
  FaImages,
  FaMapMarkerAlt,
  FaUsers,
  FaTruckLoading,
} from "react-icons/fa";
import { MdSpaceDashboard, MdConstruction } from "react-icons/md";
import type { ItemType } from "antd/es/menu/hooks/useItems";
import { BiCategory, BiArea } from "react-icons/bi";

export const MenuItem: ItemType[] = [
  {
    key: "/",
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
  },
  {
    key: "/users",
    label: "Users",
    icon: <FaUsers />,
  },
  {
    key: "/rets",
    label: "Rets Properties",
    icon: <FaHome />,
  },
  {
    key: "/itso",
    label: "Itso Properties",
    icon: <FaHome />,
  },
  {
    key: "/categories",
    label: "Categories",
    icon: <BiCategory />,
  },
  {
    key: "/blog",
    label: "Blogs",
    icon: <FaBlog />,
  },
  {
    key: "/media",
    label: "Media",
    icon: <FaImages />,
  },
  {
    key: "/area",
    label: "Area",
    icon: <BiArea />,
  },
  {
    key: "/cities",
    label: "Cities",
    icon: <FaCity />,
  },
  {
    key: "/neighbourhood",
    label: "Neighbourhood",
    icon: <FaCity />,
  },
  {
    key: "/maps",
    label: "Maps",
    icon: <FaMapMarkerAlt />,
  },
  {
    type: "divider",
  },
  {
    key: "/citiesdev",
    label: "Cities Dev",
    icon: <FaCity />,
  },
  {
    key: "/developer",
    label: "Developer",
    icon: <FaTruckLoading />,
  },
  {
    key: "/construction",
    label: "Construction",
    icon: <MdConstruction />,
  },
];
