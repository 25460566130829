import { IMap, Response } from "@types";
import { BaseAPI } from "@utils";

class MapApi extends BaseAPI<IMap> {
  async getMap(name: string): Promise<Response<any>> {
    return await this.apiClient.post(this.baseUrl + "/map", { name: name });
  }
}

export const mapApi = new MapApi("/api/v1/maps");
