import type { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IUser, Query } from "@types";
import { usersApi } from "@api";

export const useAuthor = () => {
  const [author, setAuthor] = useState<any>(undefined);

  const getAuthor = async (query?: Query<IUser>) => {
    const res = await usersApi.getMany({
      ...query,
      fields: ["name"],
      s: {
        role: "editor",
      },
      limit: 1000000,
    });

    if (res.ok) {
      let Rows: DefaultOptionType[] = [];

      res.data.data.map((user: IUser) => {
        return Rows.push({
          label: user.name,
          value: user.id,
        });
      });

      setAuthor(Rows);

      return;
    }

    if (res.error === "SERVER") {
      toast.error(res.message);

      return;
    }

    console.log(res.message);
  };

  useEffect(() => {
    getAuthor();
  }, []);

  return {
    author,
  };
};
