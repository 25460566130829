import { Query, Response } from "@types";
import { apiClient } from "./apiClient";
import { AxiosInstance } from "axios";
import qs from "qs";

export class BaseAPI<T> {
  protected apiClient: AxiosInstance;

  constructor(protected baseUrl: string) {
    this.apiClient = apiClient;
  }

  private getQuery = (query?: Query<T>) => {
    const q: string =
      qs.stringify(
        {
          fields: query?.fields,
          s: JSON.stringify(query?.s),
          page: query?.page,
          limit: query?.limit,
        },
        {
          arrayFormat: "comma",
          encode: true,
        }
      ) +
      "&" +
      qs.stringify(
        {
          join: query?.join,
        },
        { arrayFormat: "repeat" }
      );

    return q;
  };

  getMany = async (query?: Query<T>): Promise<Response<T[] | T>> => {
    const q = this.getQuery(query);
    return await apiClient.get(this.baseUrl + "?" + q);
  };

  getOne = async (
    id: number | string,
    query?: Query<T>
  ): Promise<Response<T>> => {
    const q = this.getQuery(query);
    return apiClient.get(this.baseUrl + "/" + id + "?" + q);
  };

  createOne = async (post: T): Promise<Response<T>> => {
    return await apiClient.post(this.baseUrl, post);
  };

  updateOne = async (id: number | string, update: T): Promise<Response<T>> => {
    return await apiClient.patch(this.baseUrl + "/" + id, update);
  };

  deleteOne = async (id: number | string): Promise<Response<T>> => {
    return await apiClient.delete(this.baseUrl + "/" + id);
  };
}
