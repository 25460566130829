import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "../Loader";

interface LazyRouteProps {
  children: React.ReactNode;
  check?: boolean;
}

export const LazyRoute: React.FC<LazyRouteProps> = ({
  children,
  check = true,
}) => {
  const router = <Suspense fallback={<Loader />}>{children}</Suspense>;

  if (check) {
    return localStorage.getItem("token") ? (
      router
    ) : (
      <Navigate to="/login" replace />
    );
  }

  return router;
};
