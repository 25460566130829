import { Response } from "@types";
import config from "@config";
import axios from "axios";

export const apiClient = axios.create({
  baseURL: config.API_HOST,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (!token) return config;

    config.headers = { Authorization: `Bearer ${token}` };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => {
    const pass: Response<any> = {
      data: res.data,
      ok: true,
      status: res.status,
    };

    return pass;
  },
  (error) => {
    let res: Response<any> | undefined = undefined;

    if (error.response) {
      res = {
        status: error.response.status,
        error: "SERVER",
        message: error.response.data.message,
        data: error.response.data,
        ok: false,
      };

      if (res.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      return res;
    }

    if (error.request) {
      res = {
        status: 500,
        data: error.request,
        error: "CLIENT",
        message: error.message,
        ok: false,
      };

      return res;
    }

    res = {
      status: 500,
      data: {},
      ok: false,
      error: "ANY",
      message: "Internal Server Error",
    };

    return res;
  }
);
