import { Dropdown, Empty, Menu } from "antd";
import { FaTrash } from "react-icons/fa";
import { IMedia } from "@types";
import config from "@config";
import React from "react";

interface ImageSelectorProps {
  onClick?: () => void;
  image?: IMedia;
  onRemove?: () => void;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({
  onClick,
  image,
  onRemove,
}) => {
  const menu = (
    <Menu
      items={[
        {
          label: "Remove",
          key: "Remove",
          icon: <FaTrash />,
          danger: true,
          onClick: onRemove,
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} trigger={["contextMenu"]}>
      <div onClick={onClick} className="border-ring p-1 h-32 cursor-pointer">
        {image ? (
          <img
            className="w-full h-full object-cover"
            crossOrigin="anonymous"
            src={config.IMAGE_URL + image.images?.thumbnail?.url}
            alt={image.name}
          />
        ) : (
          <Empty description={false} />
        )}
      </div>
    </Dropdown>
  );
};
