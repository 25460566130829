import { Navigate, Route, Routes } from "react-router-dom";
import { DefaultLayout } from "app/layout";
import { LazyRoute } from "@components";
import React from "react";

const Dashboard = React.lazy(() => import("./app/pages/dashboard"));
const Login = React.lazy(() => import("./app/pages/login"));

const Users = React.lazy(() => import("./app/pages/users"));
const UsersAddNew = React.lazy(() => import("./app/pages/users/AddNew"));
const UsersUpdate = React.lazy(() => import("./app/pages/users/Update"));

const Rets = React.lazy(() => import("./app/pages/rets"));
const Itso = React.lazy(() => import("./app/pages/itso"));

const Blog = React.lazy(() => import("./app/pages/blog"));
const BlogAddNew = React.lazy(() => import("./app/pages/blog/AddNew"));
const BlogUpdate = React.lazy(() => import("./app/pages/blog/Update"));

const Categories = React.lazy(() => import("./app/pages/categories"));
const CategoriesAddNew = React.lazy(
  () => import("./app/pages/categories/AddNew")
);
const CategoriesUpdate = React.lazy(
  () => import("./app/pages/categories/Update")
);

const Media = React.lazy(() => import("./app/pages/media"));

const Area = React.lazy(() => import("./app/pages/area"));
const AreaAddNew = React.lazy(() => import("./app/pages/area/AddNew"));
const AreaUpdate = React.lazy(() => import("./app/pages/area/Update"));

const City = React.lazy(() => import("./app/pages/city"));
const CityAddNew = React.lazy(() => import("./app/pages/city/AddNew"));
const CityUpdate = React.lazy(() => import("./app/pages/city/Update"));

const Neighbourhood = React.lazy(() => import("./app/pages/neighbourhood"));
const NeighbourhoodAddNew = React.lazy(
  () => import("./app/pages/neighbourhood/AddNew")
);
const NeighbourhoodUpdate = React.lazy(
  () => import("./app/pages/neighbourhood/Update")
);

const Map = React.lazy(() => import("./app/pages/map"));
const MapAddNew = React.lazy(() => import("./app/pages/map/AddNew"));
const MapUpdate = React.lazy(() => import("./app/pages/map/Update"));

const CityDev = React.lazy(() => import("./app/pages/citydev"));
const CityDevAddNew = React.lazy(() => import("./app/pages/citydev/AddNew"));
const CityDevUpdate = React.lazy(() => import("./app/pages/citydev/Update"));

const Developer = React.lazy(() => import("./app/pages/developer"));
const DeveloperAddNew = React.lazy(
  () => import("./app/pages/developer/AddNew")
);
const DeveloperUpdate = React.lazy(
  () => import("./app/pages/developer/Update")
);

const Construction = React.lazy(() => import("./app/pages/construction"));
const ConstructionAddNew = React.lazy(
  () => import("./app/pages/construction/AddNew")
);
const ConstructionUpdate = React.lazy(
  () => import("./app/pages/construction/Update")
);

export const Routers = () => {
  return (
    <div>
      <Routes>
        <Route
          path=""
          element={
            <LazyRoute>
              <DefaultLayout />
            </LazyRoute>
          }
        >
          <Route
            path="/"
            element={
              <LazyRoute>
                <Dashboard />
              </LazyRoute>
            }
          />
          <Route path="/users">
            <Route
              index={true}
              element={
                <LazyRoute>
                  <Users />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <UsersAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":id"
              element={
                <LazyRoute>
                  <UsersUpdate />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/rets">
            <Route
              index={true}
              element={
                <LazyRoute>
                  <Rets />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/itso">
            <Route
              index={true}
              element={
                <LazyRoute>
                  <Itso />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/categories">
            <Route
              index
              element={
                <LazyRoute>
                  <Categories />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <CategoriesAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":id"
              element={
                <LazyRoute>
                  <CategoriesUpdate />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/blog">
            <Route
              index={true}
              element={
                <LazyRoute>
                  <Blog />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <BlogAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <BlogUpdate />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/media">
            <Route
              index
              element={
                <LazyRoute>
                  <Media />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/area">
            <Route
              index
              element={
                <LazyRoute>
                  <Area />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <AreaAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <AreaUpdate />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/cities">
            <Route
              index
              element={
                <LazyRoute>
                  <City />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <CityAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <CityUpdate />
                </LazyRoute>
              }
            />
          </Route>

          <Route path="/neighbourhood">
            <Route
              index
              element={
                <LazyRoute>
                  <Neighbourhood />
                </LazyRoute>
              }
            />

            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <NeighbourhoodAddNew />
                </LazyRoute>
              }
            />

            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <NeighbourhoodUpdate />
                </LazyRoute>
              }
            />
          </Route>

          <Route path="/maps">
            <Route
              index
              element={
                <LazyRoute>
                  <Map />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <MapAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <MapUpdate />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/citiesdev">
            <Route
              index
              element={
                <LazyRoute>
                  <CityDev />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <CityDevAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <CityDevUpdate />
                </LazyRoute>
              }
            />
          </Route>

          <Route path="/developer">
            <Route
              index
              element={
                <LazyRoute>
                  <Developer />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <DeveloperAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <DeveloperUpdate />
                </LazyRoute>
              }
            />
          </Route>
          <Route path="/construction">
            <Route
              index
              element={
                <LazyRoute>
                  <Construction />
                </LazyRoute>
              }
            />
            <Route
              path="addnew"
              element={
                <LazyRoute>
                  <ConstructionAddNew />
                </LazyRoute>
              }
            />
            <Route
              path=":slug"
              element={
                <LazyRoute>
                  <ConstructionUpdate />
                </LazyRoute>
              }
            />
          </Route>
        </Route>

        <Route
          path="/login"
          element={
            localStorage.getItem("token") ? (
              <Navigate to="/" replace />
            ) : (
              <LazyRoute check={false}>
                <Login />
              </LazyRoute>
            )
          }
        />
      </Routes>
    </div>
  );
};
