import { Response } from "@types";
import { apiClient } from "@utils";

class SitemapApi {
  constructor(protected baseUrl: string) {}

  cities = async (): Promise<Response<any>> => {
    return await apiClient.get(this.baseUrl + "/cities");
  };

  neighbourhoods = async (): Promise<Response<any>> => {
    return await apiClient.get(this.baseUrl + "/neighbourhood");
  };

  area = async (): Promise<Response<any>> => {
    return await apiClient.get(this.baseUrl + "/area");
  };

  blogs = async (): Promise<Response<any>> => {
    return await apiClient.get(this.baseUrl + "/blogs");
  };

  properties = async (): Promise<Response<any>> => {
    return await apiClient.get(this.baseUrl + "/properties");
  };
}

export const sitemapApi = new SitemapApi("/api/v1/sitemap/xml");
