import { GalleryImages } from "./Gallery";
import { Uploader } from "./Upload";
import { Modal, Tabs } from "antd";
import { Footer } from "./Footer";
import { Panel } from "./Panel";
import { mediaApi } from "@api";
import { useAPI } from "@hooks";
import { IMedia } from "@types";
import React from "react";

interface GalleryProps {
  open?: boolean;
  onClose?: () => void;
  onImageClick?: (image: IMedia) => void;
}

export const Gallery: React.FC<GalleryProps> = ({
  open,
  onClose,
  onImageClick,
}) => {
  const { data, loading, onChangeQuery, onReload, onPageChange, pagination } =
    useAPI(mediaApi, {
      fields: ["id", "images", "name"],
    });

  return (
    <Modal
      onCancel={onClose}
      destroyOnClose
      width="70%"
      centered
      footer={<Footer onPageChange={onPageChange} pagination={pagination} />}
      title="Image Gallery"
      open={open}
    >
      <Tabs
        onChange={(activeKey) => {
          if (activeKey === "1") {
            onReload?.();
          }
        }}
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Gallery",
            children: (
              <Panel>
                <GalleryImages
                  onClick={onImageClick}
                  data={data}
                  loading={loading}
                  onReload={onReload}
                  onChangeQuery={onChangeQuery}
                />
              </Panel>
            ),
          },
          {
            key: "2",
            label: "Upload",
            children: (
              <Panel>
                <Uploader />
              </Panel>
            ),
          },
        ]}
      />
    </Modal>
  );
};
