import { Loader } from "../Loader";
import { IMedia } from "@types";
import { Card } from "./Card";
import { Input } from "antd";
import React from "react";

interface GalleryImagesProps {
  onChangeQuery: any;
  data: any;
  loading: any;
  onReload?: () => void;
  onClick?: (image: IMedia) => void;
}

export const GalleryImages: React.FC<GalleryImagesProps> = ({
  data,
  loading,
  onChangeQuery,
  onReload,
  onClick,
}) => {
  return (
    <>
      <div>
        <div className="flex justify-end">
          <div className="w-80">
            <Input.Search
              onSearch={(s) => {
                onChangeQuery?.({
                  s: {
                    name: {
                      $contL: s,
                    },
                  },
                });
              }}
              placeholder="Search image name"
            />
          </div>
        </div>
        <div className="mt-5 flex flex-wrap gap-5">
          {data?.map((image: any, index: any) => (
            <Card
              onClick={onClick}
              onLoad={onReload}
              key={index}
              image={image}
            />
          ))}
        </div>
      </div>
      {loading === true && <Loader />}
    </>
  );
};
