import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Breadcrumb, Dropdown, Layout, Menu } from "antd";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { FiLogOut } from "react-icons/fi";
import { MenuItem } from "@components";

const { Header, Content, Sider } = Layout;

export const DefaultLayout = () => {
  const breadcrumbs = useBreadcrumbs();

  const navigation = useNavigate();
  const router = useLocation();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible>
        <div className="py-4 flex justify-center items-center">
          <img src="/logo.svg" alt="logo" className="w-40" />
        </div>
        <Menu
          selectedKeys={[router.pathname]}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[router.pathname]}
          items={MenuItem}
          onClick={(info) => navigation(info.key)}
        />
      </Sider>
      <Layout className="!min-h-screen">
        <Header style={{ padding: 0 }}>
          <div className="flex justify-end items-center h-full px-5">
            <Dropdown
              trigger={["click"]}
              overlay={() => (
                <Menu
                  className="w-32"
                  items={[
                    {
                      label: "Logout",
                      key: "Logout",
                      icon: <FiLogOut />,
                      onClick: () => {
                        localStorage.removeItem("token");
                        window.location.href = "/login";
                      },
                    },
                  ]}
                />
              )}
            >
              <Avatar
                size="large"
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  cursor: "pointer",
                }}
              >
                A
              </Avatar>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: "15px 16px 0",
            overflow: "initial",
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Breadcrumb
            className="!bg-white !rounded !border !border-black !border-opacity-5 !px-3 !py-2 !shadow"
            style={{ margin: "5px 0" }}
          >
            {breadcrumbs.map(({ breadcrumb, match }, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={match.pathname}>{breadcrumb}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
