import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";

interface EditorProps {
  onChange: (c: string) => void;
  defaultValue?: string;
}

const options = {
  buttonList: [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript",
      "removeFormat",
    ],
    [
      "fontColor",
      "hiliteColor",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "table",
    ],
    [
      "link",
      "image",
      "video",
      "fullScreen",
      "showBlocks",
      "codeView",
      "preview",
      "print",
      "save",
    ],
  ],
};

export const Editor: React.FC<EditorProps> = ({ onChange, defaultValue }) => {
  return (
    <SunEditor
      defaultValue={defaultValue}
      onChange={(c) => onChange(c)}
      height="100%"
      setAllPlugins
      setOptions={options}
    />
  );
};
