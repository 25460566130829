import type { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { IDeveloper, Query } from "@types";
import { toast } from "react-toastify";
import { developerApi } from "@api";

export const useDeveloper = () => {
  const [developers, setDevelopers] = useState<any>(undefined);

  const getDeveloper = async (query?: Query<IDeveloper>) => {
    const res = await developerApi.getMany({
      ...query,
      fields: ["label"],
      limit: 100000000000,
    });

    if (res.ok) {
      let Rows: DefaultOptionType[] = [];

      res.data.data.map((developer: IDeveloper) => {
        return Rows.push({
          label: developer.label,
          value: developer.id,
        });
      });

      setDevelopers(Rows);

      return;
    }

    if (res.error === "SERVER") {
      toast.error(res.message);

      return;
    }

    console.log(res.message);
  };

  useEffect(() => {
    getDeveloper();
  }, []);

  return {
    developers,
  };
};
