import { useEffect, useState } from "react";
import { Query, Response } from "@types";
import { toast } from "react-toastify";
import { BaseAPI } from "@utils";

export interface UseAPI<T> {
  data?: readonly T[] | undefined;
  loading?: boolean;
  onPageChange?: (page: number) => void;
  onChangeQuery?: (q: Query<T>) => void;
  pagination?: PaginationResponse;
  onReload?: () => void;
}

interface PaginationResponse {
  count?: number;
  total?: number;
  page?: number;
  pageCount?: number;
}

export const useAPI = <T>(
  apiClient: BaseAPI<T>,
  query?: Query<T>,
  callback?: (data: any) => void
): UseAPI<T> => {
  const [data, setData] = useState<readonly T[] | undefined>(undefined);
  const [queries, setQueries] = useState<Query<T> | undefined>(query);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationResponse>({
    count: 12,
    page: 1,
    pageCount: 1,
    total: 0,
  });

  const getMany = async (q: Query<T>) => {
    setLoading(true);
    const res: Response<T> = await apiClient.getMany(q);
    if (res.ok) {
      setPagination({
        count: res.data.count,
        page: res.data.page,
        pageCount: res.data.pageCount,
        total: res.data.total,
      });
      let Rows: T[] = [];

      res.data.data.map((row: any) => Rows.push({ ...row, key: row.id }));

      setData(Rows);
      setLoading(false);
      callback?.(res.data.data);
      return;
    }
    setLoading(false);

    toast.error(res.message);
  };

  const onPageChange = (page: number) => {
    setQueries((pre) => ({
      ...pre,
      page,
    }));
  };

  const onChangeQuery = (q: Query<T>) => {
    setQueries((pre) => ({
      ...pre,
      ...q,
    }));
  };

  useEffect(() => {
    if (queries) {
      getMany(queries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const onReload = () => {
    if (queries) {
      getMany(queries);
    }
  };

  return {
    data,
    loading,
    onPageChange,
    onChangeQuery,
    pagination,
    onReload,
  };
};
