import { Pagination } from "antd";
import React from "react";

interface FooterProps {
  onPageChange: any;
  pagination: any;
}

export const Footer: React.FC<FooterProps> = ({ onPageChange, pagination }) => {
  return (
    <>
      <Pagination
        showSizeChanger={false}
        pageSize={12}
        onChange={onPageChange}
        total={pagination?.total}
      />
    </>
  );
};
